import React, { useContext } from "react";
import { graphql, Link, navigate } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import styled from "styled-components";

import { FirebaseContext } from "../components/Firebase";

import Seo from "../components/seo";
import { ContentBg, ContentBox, Polaroid } from "../components/ui";

const HomeWrapper = styled(ContentBg)`
  > div {
    display: flex;
    flex-wrap: wrap-reverse;
    align-items: flex-end;
  }
`;

const HomeText = styled(ContentBox)`
  flex: 2;
`;

const HomePolaroid = styled(Polaroid)`
  transform: rotate(6deg) !important;
  margin: 2rem;
  padding: 1rem;
  width: 40%;
  max-width: 800px;
  transition: all 0.5s ease-out;
  &:hover {
    transform: scale(1.2, 1.2) rotate(-3deg) !important;
  }
  figcaption {
    color: #565656;
  }
  @media screen and (max-width: 768px) {
    width: 90%;
    height: fit-content;
    padding: 0.3rem;
    text-align: center;
  }
`;

const IndexPage = props => {
  const { user } = useContext(FirebaseContext);
  const { data, location: { hostname } = {} } = props;

  console.log(props);
  const randomNumber = Math.round(
    Math.random() * data.allImageSharp.totalCount
  );
  const image = getImage(
    data.allImageSharp.nodes[randomNumber].gatsbyImageData
  );

  return (
    <HomeWrapper src={image.images.fallback.src}>
      <Seo title="Home" />
      <div>
        <HomeText>
          {user && user.name ? (
            <h1>Hello, {user.name.split(" ")[0]}!</h1>
          ) : (
            <h1>Hello, world!</h1>
          )}
          <p>
            Leuk dat je even langs komt op mijn kleine kaveltje van het
            internet!
          </p>
          <p>
            Al sinds het vorige millennium is mijn hobby het bouwen van
            websites. Ruim 20 jaar later vind ik het nog steeds erg leuk en ik
            heb de mazzel gehad dat ik van mijn hobby mijn werk heb kunnen
            maken. Nou ja, de laatste jaren iets minder... inmiddels stuur ik
            "engineering teams" aan als manager voor Amazon en hoewel dat ook
            erg leuk is, komt er van "coding" helaas niet zo veel meer. Maar
            goed, daarentegen is een website zoals deze een goed excuus om m'n
            "skills" een beetje scherp te houden en je kijkt nu naar het
            resultaat.
          </p>
          <p>
            Er zijn ongetwijfeld nog wat pagina's die niet (meer) werken, maar
            dat geeft niet... laat mij maar lekker klooien! :)
          </p>
          <p>
            Mocht je een berichtje achter willen laten, altijd leuk! Kijk even
            op de <Link to="/contact">Contact</Link> pagina.
          </p>
          <p>
            Thanks for visiting and if you didn't understand any of what you
            just read, well that's just too bad, isn't it? :)
          </p>
          <p>Mark</p>
        </HomeText>
        <HomePolaroid>
          <GatsbyImage
            image={image}
            alt="Willekeurige foto uit 20+ jaar MarkKater.nl"
          />
          <figcaption>
            👆🏻 Willekeurige foto uit 20+ jaar MarkKater.nl
          </figcaption>
        </HomePolaroid>
      </div>
    </HomeWrapper>
  );
};

export const query = graphql`
  query {
    allImageSharp {
      totalCount
      nodes {
        gatsbyImageData
      }
    }
  }
`;

export default IndexPage;
